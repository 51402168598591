var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.18.0-dev.0.1.g99637f1c5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import '@/server/utils/setupEnv';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: `${ENV.config.SENTRY_DSN}`,
  environment: `${ENV.stage}`,
  release: `${ENV.commitSha}`,
});
